import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import logo from "../../assets/images/logo.png";
import { MdArrowOutward } from "react-icons/md";
import facebook from "../../assets/icons/face-book.svg";
import instagram from "../../assets/icons/instagram.svg";
import twitter from "../../assets/icons/twitter.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import youtube from "../../assets/icons/youtube.svg";
import footerLogo from "../../assets/images/footer_logo.svg";



const data = {
  navLinks: [
    { title: "Home", handle: "/#" },
    { title: "Features", handle: "/#" },
    { title: "Benefits", handle: "/#" },
    { title: "Pricing", handle: "/#" },

  ],
};

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [openDropdown, setOpenDropdown] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const handleDropdownToggle = (i) => {
  //   setOpenDropdown(openDropdown === i ? null : i);
  // };

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "visible";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isMenuOpen]);

  return (
    <>
      <header className="sticky top-0 bg-white z-50">
        <div className="container mx-auto px-4 py-2 flex justify-between items-center">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-[218px]" />
          </Link>
          <button
            className="lg:hidden text-2xl"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <IoCloseSharp /> : <IoMenu />}
          </button>
          <nav
            className={`fixed lg:relative lg:flex bg-white h-full top-0 left-0 z-50 transform max-lg:bg-[#f3f4f6] max-lg:p-[35px] ${isMenuOpen ? "translate-x-0" : "-translate-x-full"
              } lg:transform-none transition-transform`}
          >
            <ul className="flex flex-col lg:flex-row lg:items-center">
              {data.navLinks.map((link, i) => (
                <li key={i} className="relative lg:mx-[12px] max-lg:mb-[10px]">
                  <Link
                    to={link.handle}
                    className="flex items-center justify-between lg:inline-flex text-[16px]"
                  >
                    {link.title}
                  </Link>

                </li>
              ))}
            </ul>

            <Link className="flex max-lg:mt-[8px] lg:ml-[50px] px-[15px] h-[40px] items-center text-[16px] justify-between bg-[#007AFF] text-white rounded-[30px]"
            >
              Get Started <MdArrowOutward className="ml-[6px]" />
            </Link>

          </nav>
        </div>
      </header>
      <main>{children}</main>
      <footer className="bg-bottom bg-auto bg-repeat-x " >
        <div className="lg:container mx-auto px-4">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-[40px] gap-[20px] md:py-[50px] py-[30px]">
            <div>
              <Link to="/" className="flex items-center">
                <img src={footerLogo} className="xl:w-[190px] w-[150px]" alt='Logo' />
              </Link>
              <div className="mt-[12px] md:pr-[20px]">
                <p className="text-[#6B7280] mb-0">Our intuitive dashboard lets you track, analyze, and visualize data to make smarter business decisions faster.</p>
              </div>

              <ul className="flex gap-[20px] mt-[20px]">
                <li><Link to="/"><img className="w-[25px]" src={facebook} alt="facebook" /></Link></li>
                <li><Link to="/"><img className="w-[25px]" src={instagram} alt="instagram" /></Link></li>
                <li><Link to="/"><img className="w-[25px]" src={twitter} alt="twitter" /></Link></li>
                <li><Link to="/"><img className="w-[25px]" src={linkedin} alt="linkedin" /></Link></li>
                <li><Link to="/"><img className="w-[25px]" src={youtube} alt="youtube" /></Link></li>
              </ul>
            </div>

            <div className="my-[10px] lg:my-[0]" >
              <h4 className="">About Us</h4>
              <ul className="grid gap-[10px] sm:gap-[12px]">
                <li><Link className="text-[#6B7280] hover:text-[#007AFF]" to="">Our Story</Link></li>
                <li><Link className="text-[#6B7280] hover:text-[#007AFF]" to="">FAQ</Link></li>
                <li><Link className="text-[#6B7280] hover:text-[#007AFF]" to="">Sustainability</Link></li>
                <li><Link className="text-[#6B7280] hover:text-[#007AFF]" to="">Ethical Practices</Link></li>
                <li><Link className="text-[#6B7280] hover:text-[#007AFF]" to="">Terms & Conditions</Link></li>
                <li><Link className="text-[#6B7280] hover:text-[#007AFF]" to="">Privacy Policy</Link></li>
                <li><Link className="text-[#6B7280] hover:text-[#007AFF]" to="">Shipping & Returns</Link></li>
              </ul>
            </div>

            <div className="my-[10px] lg:my-[0]">
              <h4 className=" " >Newsletter</h4>
              <p className="text-[#9CA3AF]">Subscribe to our newsletter for the latest updates, dental tips</p>
              <div className="relative">
                <input type="email" placeholder="Enter your email" className="bg-[#E5E7EB] w-full h-[45px] px-[20px] rounded-[20px]" />

                <button className="h-[40px] absolute bg-[#007AFF] text-white right-[2.5px] px-[16px] rounded-[20px] top-[2.5px]">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="lg:container mx-auto px-4">
            <div className="flex md:justify-between justify-center items-center md:flex-nowrap flex-wrap py-[25px] gap-[15px] border-t border-bg-[#9CA3AF]">
              <p className="mb-0 md:text-left text-center md:w-1/2 w-full text-[#9CA3AF]">© 2024 Logoipsum . All rights reserved.</p>
              <ul className="inline-flex justify-center items-center flex-wrap gap-x-[25px] gap-y-[10px]">
                <li className="inline-block">
                  <Link className="text-[#9CA3AF] hover:text-[#007AFF]" to='/privacy-policy'>Privacy Policy</Link>
                </li>
                <span className="text-[#9CA3AF]">|</span>
                <li className="inline-block">
                  <Link className="text-[#9CA3AF] hover:text-[#007AFF]" to='/terms-conditions'>Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
