import React from "react";
import chartAverage from "../../../assets/images/chart-average.svg";
import transferDiagonal from "../../../assets/images/circle-arrow-data-transfer-diagonal.svg";
import lockKey from "../../../assets/images/lock-key.svg";
import setup from "../../../assets/images/setup.svg";
import timeHalfPass from "../../../assets/images/time-half-pass.svg";
import shareKnowledge from "../../../assets/images/share-knowledge.svg";

const Benefits = () => {
    return (
        <section>
            <div className="container">
                <div className="md:mb-[50px] mb-[40px] border-b max-w-[400px] mx-auto border-[#D1D5DB] text-center">
                    <h2 className="text-[#1F2937] font-bold mb-[10px]">Benefits</h2>
                    <p className="mb-[12px] text-[#6B7280]">
                    Why Choose Our Analytics Dashboard?
                    </p>
                </div>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 lg:gap-[20px] gap-[15px] mt-[30px]">
                    {data.map((item, i) => (
                        <div key={i} className="xl:p-[30px] p-[20px] text-center border-[0.56px] rounded-[10px] border-[#E5E7EB]">
                            <img src={item.img} alt="" className="mx-auto mb-[20px] w-[40px]" />

                            <h4 className="text-[20px] text-[#1F2937]">{item.title}</h4>

                            <p className="text-[#6B7280]">{item.designation}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const data = [
    {
        img: timeHalfPass,
        title: "Data-Driven Decision-Making",
        designation: "Empower your strategic choices with precise ESG analytics and insights.",
    },
    {
        img: setup,
        title: "Global Compliance Assurance",
        designation: "Stay ahead of international and local regulations to avoid fines and maintain your market reputation.",
    },
    {
        img: chartAverage,
        title: "Predictive Analytics",
        designation: "Use machine learning to forecast future ESG trends and potential impacts.",
    },
    {
        img: shareKnowledge,
        title: "Strategic Benchmarking",
        designation: "Compare your ESG performance with industry peers to identify areas for improvement.",
    },
    {
        img: transferDiagonal,
        title: "Prescriptive Recommendations",
        designation: "Get actionable insights on how to improve ESG scores and address compliance gaps.",
    },
    {
        img: lockKey,
        title: "Scalable Solutions",
        designation: "Grow your ESG management capabilities alongside your business without disruption.",
    }
]

export default Benefits;
