import React from "react";


const Pricing = () => {
    return (
        <section className="bg-[#030334]">
            <div className="container">
                <div id="pricing">
                    <div class="mx-auto mt-4 max-w-7xl px-6 lg:px-8">
                        <p class="mt-2 text-4xl font-bold tracking-tight text-white ">Pricing Plans</p>
                        <p class="mt-5 max-w-[480px] pb-5  text-lg leading-8 text-white md:mb-[50px] mb-[40px] border-b border-[#9CA3AF]"> Choose the product that works best</p>
                        <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 pt-6">
                            <div class="ring-[0.56px] ring-[#6B7280] rounded-[20px] p-8 xl:p-10">
                                <div class="flex items-center justify-between gap-x-4">
                                    <h2 class="text-lg font-semibold leading-8 text-white border border-white rounded-full py-1 px-6">
                                        Basic
                                    </h2>
                                </div>
                                <p class="mt-6 flex items-baseline gap-x-1">
                                    <span class="text-4xl font-bold tracking-tight text-white">$29/month</span><span class="text-sm font-semibold leading-6 text-gray-300"></span>
                                </p>
                                <p class="mt-4 text-md leading-6 text-gray-300">Small business or individual starting with analytics</p>
                                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                </ul>

                                <a href="#" class="text-white border border-white hover[0.56px]bg-[#6B7280] focus-visible:[20px]-white mt-6 block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                                    Start Free Trail
                                </a>
                            </div>
                            <div class="ring-[0.56px] ring-[#6B7280] rounded-[20px] p-8 xl:p-10">
                                <div class="flex items-center justify-between gap-x-4">
                                    <h2 class="text-lg font-semibold leading-8 text-white border border-white rounded-full py-1 px-6">
                                        Basic
                                    </h2>
                                </div>
                                <p class="mt-6 flex items-baseline gap-x-1">
                                    <span class="text-4xl font-bold tracking-tight text-white">$29/month</span><span class="text-sm font-semibold leading-6 text-gray-300"></span>
                                </p>
                                <p class="mt-4 text-md leading-6 text-gray-300">Small business or individual starting with analytics</p>
                                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                </ul>

                                <a href="#" class="text-white border border-white hover[0.56px]bg-[#6B7280] focus-visible:[20px]-white mt-6 block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                                    Start Free Trail
                                </a>
                            </div>
                            <div class="ring-[0.56px] ring-[#6B7280] rounded-[20px] p-8 xl:p-10">
                                <div class="flex items-center justify-between gap-x-4">
                                    <h2 class="text-lg font-semibold leading-8 text-white border border-white rounded-full py-1 px-6">
                                        Basic
                                    </h2>
                                </div>
                                <p class="mt-6 flex items-baseline gap-x-1">
                                    <span class="text-4xl font-bold tracking-tight text-white">$29/month</span><span class="text-sm font-semibold leading-6 text-gray-300"></span>
                                </p>
                                <p class="mt-4 text-md leading-6 text-gray-300">Small business or individual starting with analytics</p>
                                <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-6 w-5 flex-none text-white">
                                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd"></path>
                                        </svg>
                                        Access to all core analytics features
                                    </li>
                                </ul>

                                <a href="#" class="text-white border border-white hover[0.56px]bg-[#6B7280] focus-visible:[20px]-white mt-6 block rounded-full py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                                    Start Free Trail
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};



export default Pricing;
