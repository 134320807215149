import React from 'react'
import { MdArrowOutward } from 'react-icons/md'
import { Link } from 'react-router-dom'
import banner from "../../../assets/images/banner.png";
import client1 from "../../../assets/images/client1.png";
import client2 from "../../../assets/images/client2.png";
import client3 from "../../../assets/images/client3.png";

const Banner = () => {
    return (

        <section className='relative bg-[#f9fcff] pb-0'>
            <div className='container'>
                <div className='max-w-[480px] relative z-10'>
                    <h1 className='lg:text-[35px] md:text-[30px] text-[28px] text-bold'>Empowering Your ESG Journey with Advanced Data Analysis</h1>
                    <p>Unlock the potential of your organization with data-driven insights and comprehensive ESG tools.</p>
                    <div>
                        <Link className="inline-flex px-[15px] h-[40px] items-center text-[16px] justify-between bg-[#007AFF] text-white rounded-[30px]"
                        >
                            Get Started <MdArrowOutward className="ml-[6px]" />
                        </Link>
                    </div>

                    <div class="mt-[30px] border border-[#3B82F6] inline-block p-[12px] rounded-[20px] sm:mb-[60px]">
                        <h3 className='mb-[20px] text-[18px] font-medium'>Our Happy Customers</h3>
                        <ul className='flex items-center pl-[15px] '>
                            <li className='-ml-[15px] w-[50px] h-[50px] rounded-full'><img src={client1} alt='client1' /></li>
                            <li className='-ml-[15px] w-[50px] h-[50px] rounded-full'><img src={client2} alt='client2' /></li>
                            <li className='-ml-[15px] w-[50px] h-[50px] rounded-full'><img src={client3} alt='client3' /></li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className='h-full sm:absolute bottom-0 right-0 sm:w-[60%] w-full mr-auto max-sm:pl-[20px]'>
                <img className='h-full w-full object-cover object-left' src={banner} alt='banner' />
            </div>
        </section>

    )
}

export default Banner
