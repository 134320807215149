import React from 'react'
import Layout from '../components/shared/Layout'
import Banner from '../components/shared/home/Banner'
import Features from '../components/shared/home/Features'
import Benefits from '../components/shared/home/Benefits'
import LogoSlider from '../components/shared/home/LogoSlider'
import Pricing from '../components/shared/home/Pricing'
import Testimonial from '../components/shared/home/Testimonial'

const Home = () => {
  return (
    <Layout>
     <Banner />
     <Features />
     <Benefits />
     <LogoSlider />
     <Testimonial />
     <Pricing />
    </Layout>
  )
}

export default Home
